.app {
  width: 100vw;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.app__header {
  color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
}

.app__header__action {
  padding: 8px;
  font-size: 20px;
  color: #fff;
}

.app__marker-watermark {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__marker-watermark img {
  opacity: 0.35;
  max-height: 70%;
  max-width: 70%;
}

@media (orientation: landscape) {
  .app__header {
    justify-content: flex-start;
  }
}
