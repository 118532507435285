:root {
    --primary-color: #FB6D00;
}

@font-face {
    font-family: "Gilroy";
    src: url("./fonts/Gilroy-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "Gilroy";
    src: url("./fonts/Gilroy-SemiBold.ttf") format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: "Gilroy";
    src: url("./fonts/Gilroy-Bold.ttf") format("truetype");
    font-weight: bold;
}

body {
    margin: 0;
    font-family: 'Gilroy', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.clickable {
    cursor: pointer;
}

.ant-modal-content.ant-modal-content.ant-modal-content {
    border-radius: 32px;
}

.ant-slider-handle::after {
    box-shadow: 0 0 0 2px #FB6D00 !important;
    background-color: #FB6D00 !important;
}

.ant-slider-handle, .ant-slider-track {
    background-color: #FB6D00 !important;
}

.ant-slider-rail.ant-slider-rail.ant-slider-rail {
    background-color: rgba(255, 255, 255, 0.2);
}

.ant-btn span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

a {
    color: var(--primary-color);
}
