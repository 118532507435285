.loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: rgba(255, 255, 255, 0.5);
  z-index: 2;
  backdrop-filter: blur(13px);
}

.loading__progress {
  width: 50%;
}

.loading__message {
  margin-top: 10px;
}
