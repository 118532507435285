.marker-watermark {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.marker-watermark img {
  opacity: 0.35;
  max-height: 70%;
  max-width: 70%;
}
@media (orientation: landscape) {
  .watermark {
    top: unset;
    bottom: 0;
  }
}
