.toolbar-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 90px;
}

.toolbar-button .label {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    margin-top: 4px;
}

.toolbar-button .icon {
    width: 32px;
    height: 32px;
}
