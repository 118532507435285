.progress {
    min-width: 200px;
    width: 100%;
    border-radius: 10px;
    height: 6px;
    background-color: rgba(0,0,0,0.2);
    overflow: hidden;
}

.progress__indetermined {
     position: relative;
     animation-name: indetermined;
     animation-duration: 1s;
     animation-iteration-count: infinite;
 }

.progress__bar {
     height: 100%;
     border-radius: 10px;
     background-color: #FB6D00;
     transition: width 0.2s ease-in-out;
 }

@keyframes indetermined {
    from  {left: -30%;}
    to    {left: 100%;}
}
