.player {
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.player__fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #000;
  overflow: hidden;
}

.player__controls {
  z-index: 2;
  position: fixed;
  bottom: 2px;
  left: 2px;
  right: 2px;
}

.player__seeker {
  padding: 0 16px;
}

video {
  width: 100%;
  height: 100%;
}

@media (orientation: landscape) {
  .player__controls {
    right: 2px;
    left: unset;
    top: 2px;
    bottom: 2px;
  }

  .player__seeker {
    position: fixed;
    bottom: 8px;
    left: 0;
    right: 113px;
  }
}
