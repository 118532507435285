.toolbar-controls {
    height: 114px;
    backdrop-filter: blur(48px);
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;
}

@media (orientation: landscape) {
    .toolbar-controls {
        height: 100%;
        flex-direction: column;
        justify-content: space-around;
    }
}
