.header {
    color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
}

.header__action {
    padding: 8px;
    font-size: 20px;
    color: #fff;
}

@media (orientation: landscape) {
    .header {
        justify-content: flex-start;
    }
}
