.call-to-action {
    /*padding: 48px 28px;*/
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.call-to-action__button {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.call-to-action img {
    width: 100%;
    height: auto;
    max-height: 50%;
    margin-bottom: 16px;
}

.call-to-action .content {
    margin-bottom: 20px;
}

.call-to-action .description {
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    font-size: 16px;
}

.call-to-action .title {
    font-weight: 700;
    font-size: 32px;
}

.call-to-action button {
    border-radius: 10px;
}
