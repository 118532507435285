.toolbar {
    height: 114px;
    backdrop-filter: blur(48px);
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
}

.instructions-title {
    max-width: 50%;
    text-align: center;
}

.toolbar-instructions {
    flex-direction: column;
    text-align: start;
    align-items: flex-start;
    justify-content: center;
    padding: 0 32px;
    min-height: 114px;
    font-size: 14px;
    font-weight: 600;
}

@media (orientation: landscape) {
    .toolbar {
        height: 100%;
        width: 113px;
    }

    .toolbar__content {
        font-size: 16px;
        font-weight: 400;
        max-width: 228px;
        color: #fff;
        text-align: center;
    }
}
