.loading-error {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 20px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    background: #fff;
}

.loading-error__title {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 8px;
    max-width: 80%;
}

.loading-error__message {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    max-width: 80%;
}

.loading-error__icon {
    font-size: 40px;
    margin-bottom: 18px;
}
